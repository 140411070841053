import { Component, OnInit } from '@angular/core';
import { FaqService } from '../services/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private faqService: FaqService) { }

  public faq: any = []
  ngOnInit() {
    this.getFaq();
  }

  getFaq() {
    this.faqService.getFaq().subscribe(response => {
      this.faq = response;
    });
  }

}
