import { Component, OnInit } from '@angular/core';
import { FaqService } from '../services/faq.service';

@Component({
  selector: 'app-termos-de-uso',
  templateUrl: './termos-de-uso.component.html',
  styleUrls: ['./termos-de-uso.component.css']
})
export class TermosDeUsoComponent implements OnInit {


  constructor(private faqService: FaqService) { }

  public faq: any = []
  ngOnInit() {
    this.getTermos();
  }

  getTermos() {
    this.faqService.getTermos().subscribe(response => {
      this.faq = response;
    });
  }

}
