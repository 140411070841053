import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaqComponent } from './faq/faq.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { TermosDeUsoComponent } from './termos-de-uso/termos-de-uso.component';

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    TermosDeUsoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent, FaqComponent, TermosDeUsoComponent]
})
export class AppModule { }
